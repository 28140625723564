import { memo, useMemo } from "react";

import moment from "moment";
import { useTranslation } from "next-i18next";
import { Container, Row } from "react-bootstrap";

import { settings } from "@aft/client-settings";

/**
 * Footer for not-authorized layout.
 */
export const Footer = memo(() => {
  const { t } = useTranslation("common");

  return useMemo(() => {
    const copyrightYear = `${settings.Information.CreationYear}${
      settings.Information.CreationYear !== moment.utc().year() ? `-${moment.utc().year()}` : ""
    }`;

    return (
      <footer role="contentinfo" className="bg-dark text-light py-4">
        <Container fluid="md">
          <Row className="justify-content-center align-items-center">
            <p>{t("Footer.Copyright", { year: copyrightYear })}</p>
          </Row>
        </Container>
      </footer>
    );
  }, [t]);
});
