import { memo, useMemo } from "react";

import { Container, Row } from "react-bootstrap";

import {
  useAuthorizationStateWatcher,
  PageTypesByAuthorizationStatus,
} from "@aft/client-components/authorization/hooks/useAuthorizationStateWatcher";
import { Notifications } from "@aft/client-components/core/Notifications";

import { Footer } from "./components/Footer";
import { Header } from "./components/Header";

/**
 * Layout to be applied to pages that should be visible to not-authorized users.
 */
export const NotAuthorizedLayout = memo(({ children, pageTypeByAuthorizationStatus }) => {
  useAuthorizationStateWatcher(
    pageTypeByAuthorizationStatus || PageTypesByAuthorizationStatus.NotAuthorized,
  );

  return useMemo(
    () => (
      <>
        <Header />
        <Row
          noGutters
          className="flex-grow-1 overflow-y-auto flex-column flex-nowrap justify-content-space-between"
        >
          <main className="flex-grow-1">
            <Notifications />
            <Container fluid="xl" className="py-4 py-md-5">
              <Row noGutters className="flex-column">
                {children}
              </Row>
            </Container>
          </main>
          <Footer />
        </Row>
      </>
    ),
    [children],
  );
});
