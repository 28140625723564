import { memo } from "react";

import NextLink from "next/link";
import { Container, Navbar } from "react-bootstrap";

import { clientRoutesManager } from "@aft/client-routes";

/**
 * Header for not-authorized layout.
 */
export const Header = memo(() => (
  <Navbar className="shadow-sm bg-white z-index-1">
    <Container fluid="xl">
      <NextLink href={clientRoutesManager.rootNotAuthorizedRoute.pathname} passHref>
        <Navbar.Brand>
          <img src="/images/logo/aft-dark.png" height="27" alt="AFT" />
        </Navbar.Brand>
      </NextLink>
    </Container>
  </Navbar>
));
