import { getServerSideNotAuthorizedPageProps } from "@aft/client-ssr-middleware";

import { NotAuthorizedLayout } from "components/Layout/NotAuthorized";
import { AuthorizationSignIn } from "containers/Authorization/SignIn";

/**
 * Server-side properties pre-processing function.
 */
export const getServerSideProps = getServerSideNotAuthorizedPageProps();

AuthorizationSignIn.getLayout = (page) => <NotAuthorizedLayout>{page}</NotAuthorizedLayout>;

export default AuthorizationSignIn;
