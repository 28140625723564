import React, { memo, useCallback, useMemo } from "react";

import { Formik } from "formik";
import { useTranslation } from "next-i18next";
import { Row, Col, Card, Button } from "react-bootstrap";
import { connect } from "react-redux";

import { NextLink } from "@aft/client-components/core/NextLink";
import { FormInputField, Form } from "@aft/client-components/core/form";
import { authorizationActions, SignInReasons } from "@aft/client-modules/authorization";
import { clientRoutesManager } from "@aft/client-routes";
import {
  FormFieldNames,
  createEmailFieldValidator,
  createPasswordFieldValidator,
  createFormValidator,
} from "@aft/client-services/forms";
import { settings } from "@aft/client-settings";

export const AuthorizationSignInComponent = memo(
  ({ account: { user }, authorization: { signInReason }, signInRequest }) => {
    const { t } = useTranslation("common");

    const handleFormSubmit = useCallback(
      (values) => {
        signInRequest(values);
      },
      [signInRequest],
    );

    const renderTitle = useCallback(() => {
      let title, description;

      switch (signInReason) {
        case SignInReasons.AccountCreated:
          title = t("SignIn.Text.AccountCreated.Title", {
            name: `${user.firstName}${user.lastName ? ` ${user.lastName}` : ""}`,
          });
          description = t("SignIn.Text.AccountCreated.Description");

          break;
        case SignInReasons.EmailUpdated:
          description = t("SignIn.Text.EmailUpdated");

          break;
        case SignInReasons.PasswordUpdated:
          description = t("SignIn.Text.PasswordUpdated");

          break;
        case SignInReasons.PhoneNumberUpdated:
          description = t("SignIn.Text.PhoneNumberUpdated");

          break;
        default:
          break;
      }

      return (
        <>
          {(!!title || !!description) && (
            <Col xs="12" className="py-3">
              <Row className="my-n2">
                {!!title && (
                  <Col xs="12" className="py-2">
                    <h1>{title}</h1>
                  </Col>
                )}
                {!!description && (
                  <Col xs="12" className="py-2">
                    <p>{description}</p>
                  </Col>
                )}
              </Row>
            </Col>
          )}
          <Col xs="12" className="py-3">
            {title ? <h2>{t("SignIn.Title")}</h2> : <h1>{t("SignIn.Title")}</h1>}
          </Col>
        </>
      );
    }, [t, user.firstName, user.lastName, signInReason]);

    return useMemo(
      () => (
        <Row className="my-n3">
          {renderTitle()}
          <Col xs="12" sm="8" lg="6" className="py-3">
            <Card className="shadow-sm">
              <Card.Body>
                <Formik
                  initialValues={{
                    [FormFieldNames.Email]: settings.User.Default.Email || "",
                    [FormFieldNames.Password]: settings.User.Default.Password || "",
                  }}
                  validate={createFormValidator(t, [
                    createEmailFieldValidator(FormFieldNames.Email),
                    createPasswordFieldValidator(FormFieldNames.Password),
                  ])}
                  onSubmit={handleFormSubmit}
                >
                  <Form>
                    <Row className="my-n4">
                      <Col xs="12" className="py-4">
                        <Row className="my-n3">
                          <Col xs="12" className="py-3">
                            <FormInputField name={FormFieldNames.Email} />
                          </Col>
                          <Col xs="12" className="py-3">
                            <FormInputField name={FormFieldNames.Password} />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" className="py-4">
                        <Button type="submit">{t("SignIn.Buttons.SignIn")}</Button>
                      </Col>
                    </Row>
                  </Form>
                </Formik>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12" className="py-3">
            <Row className="my-n1">
              <Col xs="12" className="py-1">
                <NextLink
                  href={
                    clientRoutesManager.getRoute(
                      clientRoutesManager.Segments.Authorization,
                      clientRoutesManager.Segments.Password,
                      clientRoutesManager.Segments.Reset,
                      clientRoutesManager.Segments.Request,
                    ).pathname
                  }
                >
                  {t("SignIn.Buttons.ForgotPassword")}
                </NextLink>
              </Col>
              <Col xs="12" className="py-1">
                <NextLink
                  href={
                    clientRoutesManager.getRoute(
                      clientRoutesManager.Segments.Authorization,
                      clientRoutesManager.Segments.SignUp,
                    ).pathname
                  }
                >
                  {t("SignIn.Buttons.SignUp")}
                </NextLink>
              </Col>
            </Row>
          </Col>
        </Row>
      ),
      [t, renderTitle, handleFormSubmit],
    );
  },
);

const mapStateToProps = ({ account, authorization }) => ({ account, authorization });

/**
 * Authorization sign in page.
 */
export const AuthorizationSignIn = connect(mapStateToProps, {
  signInRequest: authorizationActions.signInRequest,
})(AuthorizationSignInComponent);
